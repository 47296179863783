export const environment = {
  production: true,
  sentryEnvironment: 'production',
  clientId: 'vtg-ui',
  clientRoot: 'https://app.vertigoinsurance.com/',
  stsAuthority: 'https://app.vertigoinsurance.com/idp/',
  baseAuthUrl: '/api/auth/',
  baseSubmissionUrl: '/api/submission/',
  baseVectorUrl: '/api/vector/',
  baseRateEngineUrl: '/api/ratingengine/',
  baseNotificationUrl: '/api/notification/',
  baseTaskUrl: '/api/task/',
  baseCompanyUrl: '/api/company/',
  baseResourceUrl: '/api/resource/',
  baseNoteUrl: '/api/note/',
  basePhotoBlobUrl: 'https://storageprodvertigo.blob.core.windows.net/pictures/',
  baseMapTilesBlobUrl: 'https://storageprodvertigo.blob.core.windows.net/map-tiles/tilesWData/'
};
